import React from 'react';
import BrowserDiscipline from '../components/HomeScreen/BrowserDiscipline';

const DisciplinesScreen = () => {

  return (
    <main className="py-24">

      <BrowserDiscipline />

    </main>
  )
}

export default DisciplinesScreen