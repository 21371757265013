import React from 'react'

const SectionTitle = ({title}) => {
  return (
    <div className=" mb-12 lg:mb-0">
      <div className="" data-aos="fade-up" data-aos-duration="1200">
              <h2 className=" text-center text-3xl pb-1 lg:text-4xl font-bold title_text" >{title}</h2>
        <div className="w-24 h-1 mt-2 mx-auto grad_button rounded-full"></div>
      </div>
    </div>
  )
}

export default SectionTitle
