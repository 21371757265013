import React from 'react';
import SectionTitle from '../components/SectionTitle';

const FeaturesScreen = () => {

  return (
    <main className="py-28">

      <section className="max-w-screen-xl m-auto p-6">

        <SectionTitle title="Features and Supports" />
        <div className="grid grid-cols-1 md:grid-cols-2 my-6 lg:grid-cols-3 gap-6 ">

          {/* box1  */}
          <div className="bg-white rounded-lg p-6 flex flex-col space-y-2" data-aos="fade-up"
            data-aos-duration="1200">

            {/* headline  */}
            <div>
              <h3 className="font-semibold text-gray-700 text-lg">Application Strategy and Planning</h3>
            </div>

            {/* details  */}
            <div className="px-8 text-gray-500 text-sm">
              <ul style={{ listStyleType: 'disc' }}>
                <li>Initial consultation</li>
                <li>Program selection</li>
                <li>Timeline creation</li>
              </ul>

            </div>

          </div>

          {/* box 2 */}
          <div className="bg-white rounded-lg p-6 flex flex-col space-y-2" data-aos="fade-up"
            data-aos-duration="1200" data-aos="fade-up"
            data-aos-duration="1200">

            {/* headline  */}
            <div>
              <h3 className="font-semibold text-gray-700 text-lg">Document Preparation</h3>
            </div>

            {/* details  */}
            <div className="px-8 text-gray-500 text-sm">
              <ul style={{ listStyleType: 'disc' }}>
                <li> Statement of Purpose (SOP) assistance</li>
                <li>Personal statement help</li>
                <li>Resume/CV crafting</li>
                <li>Writing sample feedback</li>
              </ul>

            </div>

          </div>


          {/* box 3*/}
          <div className="bg-white rounded-lg p-6 flex flex-col space-y-2" data-aos="fade-up"
            data-aos-duration="1200">

            {/* headline  */}
            <div>
              <h3 className="font-semibold text-gray-700 text-lg">Recommendation Letters</h3>
            </div>

            {/* details  */}
            <div className="px-8 text-gray-500 text-sm">
              <ul style={{ listStyleType: 'disc' }}>
                <li>Recommender selection advice</li>
                <li>Preparation of information packets for recommenders</li>
              </ul>

            </div>

          </div>

          {/* box 4*/}
          <div className="bg-white rounded-lg p-6 flex flex-col space-y-2" data-aos="fade-up"
            data-aos-duration="1200">

            {/* headline  */}
            <div>
              <h3 className="font-semibold text-gray-700 text-lg">Interview Preparation</h3>
            </div>

            {/* details  */}
            <div className="px-8 text-gray-500 text-sm">
              <ul style={{ listStyleType: 'disc' }}>
                <li>Mock interviews</li>
                <li>Performance feedback and improvement tips</li>
              </ul>

            </div>

          </div>

          {/* box 5*/}
          <div className="bg-white rounded-lg p-6 flex flex-col space-y-2" data-aos="fade-up"
            data-aos-duration="1200">

            {/* headline  */}
            <div>
              <h3 className="font-semibold text-gray-700 text-lg">Portfolio Development (for relevant fields)</h3>
            </div>

            {/* details  */}
            <div className="px-8 text-gray-500 text-sm">
              <ul style={{ listStyleType: 'disc' }}>
                <li> Guidance on portfolio content and format</li>
                <li> Feedback on portfolio pieces</li>
              </ul>

            </div>

          </div>


          {/* box 6*/}
          <div className="bg-white rounded-lg p-6 flex flex-col space-y-2" data-aos="fade-up"
            data-aos-duration="1200">

            {/* headline  */}
            <div>
              <h3 className="font-semibold text-gray-700 text-lg">Application Form Assistance</h3>
            </div>

            {/* details  */}
            <div className="px-8 text-gray-500 text-sm">
              <ul style={{ listStyleType: 'disc' }}>
                <li> Help with completing application forms accurately</li>
                <li> Review of application before submission</li>
              </ul>

            </div>

          </div>

          {/* box 7*/}
          <div className="bg-white rounded-lg p-6 flex flex-col space-y-2" data-aos="fade-up"
            data-aos-duration="1200">

            {/* headline  */}
            <div>
              <h3 className="font-semibold text-gray-700 text-lg">Financial Aid and Scholarships</h3>
            </div>

            {/* details  */}
            <div className="px-8 text-gray-500 text-sm">
              <ul style={{ listStyleType: 'disc' }}>
                <li>Information on financial aid options</li>
                <li>Guidance on scholarship applications</li>
              </ul>

            </div>

          </div>

          {/* box 8*/}
          <div className="bg-white rounded-lg p-6 flex flex-col space-y-2" data-aos="fade-up"
            data-aos-duration="1200">

            {/* headline  */}
            <div>
              <h3 className="font-semibold text-gray-700 text-lg">Follow-Up and Status Tracking</h3>
            </div>

            {/* details  */}
            <div className="px-8 text-gray-500 text-sm">
              <ul style={{ listStyleType: 'disc' }}>
                <li>Assistance with tracking application status</li>
                <li>Guidance on follow-up communications with programs</li>
              </ul>

            </div>

          </div>



        </div>
      </section>

    </main>
  )
}

export default FeaturesScreen