import React from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import Footer from "./components/Footer";
import Navbar from "./components/NavBar";
import ScrollToTop from "./components/ScrollToTop";
import ContactScreen from "./pages/ContactScreen";
import DisciplineScreenSingle from "./pages/DisciplineScreenSingle";
import DisciplinesScreen from "./pages/DisciplinesScreen";
import ErrorScreen from "./pages/ErrorScreen";
import FeaturesScreen from "./pages/FeaturesScreen";
import HomeScreen from "./pages/HomeScreen";

function App() {
  return (
    <Router>
      <ScrollToTop />
      <Navbar />
        <Routes>
          <Route
          exact
            path="/"
            element={<HomeScreen />}
          />
          <Route
            path="/contact"
            element={<ContactScreen />}
          />
          
          <Route
            path="/features"
            element={<FeaturesScreen />}
          />
          
          <Route
          path="/disciplines"
            element={<DisciplinesScreen />}
          />
          <Route
          path="/disciplines/:link"
            element={<DisciplineScreenSingle />}
          />

        <Route path="*" element={<ErrorScreen /> }/>

        </Routes>

        <Footer />
    </Router>
  );
}

export default App;
