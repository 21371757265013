import React from 'react';
import { Link } from 'react-router-dom';
import SectionTitle from '../SectionTitle';

const MakeGraduateSchool = () => {
    return (
        <section className="max-w-screen-xl mx-auto px-6 my-24">

            {/* title  */}
            <div>
                <SectionTitle title="Making Graduate school accessible " />
            </div>


            <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5 w-full gap-20 place-items-center pt-12">
                <div className="col-span-1 md:col-span-1 lg:col-span-2">
                    <img
                        className="object-contain w-96 h-96 -mt-16"
                        src="../../../images/grad.svg"
                        alt="grad"
                        data-aos="fade-up"
                        data-aos-duration="1200"
                    />
                </div>

                <div className="col-span-1 md:col-span-2 lg:col-span-3 flex flex-col space-y-5 h-full" data-aos="fade-up"
                    data-aos-duration="1200">
                    
                    <div className="flex flex-col space-y-3">
                        <h2 className="text-xl font-semibold text-gray-700" data-aos="fade-up"
                            data-aos-duration="1200">Affordability, at It's peak!</h2>
                        <p className="text-sm lg:text-base text-gray-600 text-justify" data-aos="fade-up"
                            data-aos-duration="1400">At Gradpeak, we are committed to making graduate school both accessible and affordable. Our team of experienced consultants offers personalized guidance and resources to help you navigate the complexities of the grad school admission process. From application assistance to financial planning, we provide comprehensive support to ensure a smooth journey toward your academic and career goals. We understand the challenges and costs associated with higher education, so we work diligently to minimize financial burdens, allowing you to focus on your studies and future success. With Gradpeak, achieving your dream of attending grad school is not only possible but also affordable. Our mission is to empower students with the tools and knowledge they need to excel.</p>

                        <Link to="/features">
                            <button
                                className="grad_button w-40 px-5 py-3 lg:px-5 lg:py-3 text-sm lg:text-base rounded-lg  text-white font-semibold"
                                data-aos="fade-up"
                                data-aos-duration="1600"
                            >
                                Browse Features
                            </button>
                            </Link>
                       
                    </div>
                </div>

                {/* vectors */}
               
            </div>

        </section>
    )
}

export default MakeGraduateSchool