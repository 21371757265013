import React, { useState } from 'react';
import { FaFacebook, FaPhoneAlt } from "react-icons/fa";
import { FaLocationDot } from "react-icons/fa6";
import { MdEmail } from "react-icons/md";
import SectionTitle from '../SectionTitle';


const ContactSec = () => {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
    const [subject, setSubject] = useState("");


    const submitForm = () => {
        window.open(
            `mailto:smabtahinoor@gmail.com?subject=${encodeURIComponent(
                subject
            )}&body=${encodeURIComponent(name)} (${encodeURIComponent(
                email
            )}): ${encodeURIComponent(message)}`
        );
    };
  return (
    <>
          <SectionTitle title="Contact" />

          <section className="max-w-screen-xl mx-auto px-6 py-8 grid grid-cols-1 lg:grid-cols-2 gap-10">

              <div className="col-span-1 lg:col-span-1">

                  <div className="flex flex-col space-y-3 -mt-20" data-aos="fade-up"
                      data-aos-duration="1200">
                      <img className="object-contain w-full h-auto" src="../../images/contact.svg" alt="contact" />
                      <div>
                          <ul className="text-sm text-gray-500 text-justify flex flex-col ">
                              <li className="hover:underline cursor-pointer flex flex-row items-center space-x-2"> <FaLocationDot />  <span>Address: Mohakhali DOHS, Dhaka, Bangaladesh</span>  </li>
                              <li className="hover:underline cursor-pointer flex flex-row items-center space-x-2 "> <MdEmail /> <span> Email: gradpeak.llc@gmail.com</span>  </li>
                              <li className="hover:underline cursor-pointer flex flex-row items-center space-x-2"> <FaPhoneAlt /> <span>Phone: 01306990152 </span>   </li>
                              <li className="hover:underline cursor-pointer flex flex-row items-center space-x-2">  <FaFacebook /> <a href="https://www.facebook.com/profile.php?id=61558121583496&mibextid=LQQJ4d" target="_blank">Facebook Page</a>  </li>
                          </ul>
                      </div>
                  </div>



              </div>

              <div className="col-span-1 lg:col-span-1" data-aos="fade-up"
                  data-aos-duration="1200">
                  <form onSubmit={submitForm}>
                      <fieldset className="flex flex-col space-y-2 ">
                          <div className="flex flex-col space-y-2">
                              <label htmlFor="contactName" className="text-sm">
                                  Name <span className="required text-sm">*</span>
                              </label>
                              <input
                                  className="py-4 outline-none"
                                  type="text"
                                  defaultValue=""
                                  value={name}

                                  id="contactName"
                                  name="contactName"
                                  onChange={(e) => setName(e.target.value)}
                              />
                          </div>

                          <div className="flex flex-col space-y-2">
                              <label htmlFor="contactEmail" className="text-sm">
                                  Email <span className="required">*</span>
                              </label>
                              <input
                                  className="py-4 outline-none"
                                  type="email"
                                  defaultValue=""
                                  value={email}

                                  id="contactEmail"
                                  name="contactEmail"
                                  onChange={(e) => setEmail(e.target.value)}
                              />
                          </div>

                          <div className="flex flex-col space-y-2">
                              <label htmlFor="contactSubject" className="text-sm">Subject</label>
                              <input
                                  className="py-4 outline-none"
                                  type="text"
                                  defaultValue=""
                                  value={subject}

                                  id="contactSubject"
                                  name="contactSubject"
                                  onChange={(e) => setSubject(e.target.value)}
                              />
                          </div>

                          <div className="flex flex-col space-y-2">
                              <label htmlFor="contactMessage" className="text-sm">
                                  Message <span className="required">*</span>
                              </label>
                              <textarea
                                  className="py-4 outline-none"
                                  cols="30"
                                  rows="5"
                                  value={message}
                                  onChange={(e) => setMessage(e.target.value)}
                                  id="contactMessage"
                                  name="contactMessage"
                              ></textarea>
                          </div>

                          <div className="mt-2">
                              <button
                                  className="grad_button w-32 mt-2 px-5 py-3 lg:px-5 lg:py-3 text-sm lg:text-base rounded-lg  text-white font-semibold"
                                  onClick={submitForm} type="submit"
                              >
                                  Submit
                              </button>

                          </div>
                      </fieldset>
                  </form></div>


          </section>
    </>
  )
}

export default ContactSec