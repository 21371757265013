import React from 'react';
import { useNavigate } from "react-router-dom";

const ErrorScreen = () => {


    const navigate = useNavigate();

    const handleNavigation = () => {
        navigate("/");
    };



  return (
    <section className="flex flex-col items-center justify-center h-screen">
          <img className="relative hero_section_vector object-contain" src="../../images/erro.svg" alt="error" data-aos="fade-up"
              data-aos-duration="1200" />

          <button
              className="grad_button px-5 py-3 lg:px-5 lg:py-3 text-sm lg:text-base border border-safe-600  rounded-lg hover:bg-safe-600 text-white font-semibold"
              onClick={handleNavigation}
              data-aos="fade-up"
              data-aos-duration="1300"
          >
              Back to home
          </button>
    </section>
  )
}

export default ErrorScreen