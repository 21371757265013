import React from 'react';
import { Link } from "react-router-dom";
import SectionTitle from '../SectionTitle';


const BrowserDiscipline = () => {

  const dept = [
    { id: 1, name: "Engineering", image: "../../../images/disc/1.png", link: "/disciplines/engineering" ,duration: "1100",  },
    { id: 2, name: "Social Sciences", image: "../../../images/disc/2.png", link: "/disciplines/Social Sciences" ,duration: "1200",  },
    { id: 3, name: "Arts & Humanities", image: "../../../images/disc/3.png", link: "/disciplines/Arts & Humanities" ,duration: "1300",  },
    { id: 4, name: "Public Health & Medicine", image: "../../../images/disc/4.png", link: "/disciplines/Public Health & Medicine" ,duration: "1400",  },
    {
      id: 5, name: "Biological Sciences", image: "../../../images/disc/5.png", link: "/disciplines/Biological Sciences" ,duration: "1500",  },
    { id: 6, name: "Science & Applied Sciences", image: "../../../images/disc/6.png", link: "/disciplines/Science & Applied Sciences" ,duration: "1600",  },

   
  ]


  return (
    <section className="max-w-screen-xl mx-auto px-6 my-36" id="discipline">

      <SectionTitle title="Browse Discipline"   />
      <div className="grid grid-cols-1 md:grid-cols-2 my-6 lg:grid-cols-3 gap-6 max-w-screen-xl m-auto p-6" > 

       
       

        {dept?.map(item => {
          return (
            <Link key={item?.id} to={item?.link} data-aos="fade-up"
              data-aos-duration={item?.duration}>
              <div className="select-none overflow-hidden flex flex-row p-4 space-x-3 bg-white rounded-lg" >
                <div className="w-16 h-16 rounded-tr-lg rounded-tl-lg overflow-hidden">
                  <img className="w-full h-full object-cover" src={item?.image} alt={item?.link} />
                </div>

                {/* view option  */}
                  <div className="flex flex-col justify-center space-y-0">
                    <h2 className="text-safe-600 text-lg font-semibold">{item?.name}</h2>
                    <Link className="text-gray-400 hover:underline text-xs" to={item?.link}>Explore</Link>
                  </div>
              </div>
            </Link>
          )
        })}

    </div>
    </section>
  )
}



export default BrowserDiscipline