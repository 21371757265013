import React, { useState } from "react";
import { HiMenuAlt3 } from "react-icons/hi";
import { Link, useLocation } from "react-router-dom";

const Navbar = () => {
  const [mobileNav, setMobileNav] = useState(false);
  const [header, setHeader] = useState(false);
  const location = useLocation()


  const menu = [
    { id: 1, text: "Home", to: "/" },
    { id: 2, text: "Features", to: "/features" },
    { id: 3, text: "Contact", to: "/contact" },
  ];

  const handleClick = () => {
    setMobileNav(!mobileNav);
  };

  const changeHeader = () => {
    if (window.scrollY >= 80) {
      setHeader(true);
    } else {
      setHeader(false);
    }
  };

  window.addEventListener("scroll", changeHeader);

  return (
    <header
      className={
        !header
          ? "fixed w-full z-10 py-1 bg-transparent"
          : "bg-white z-10 fixed w-full py-1"
      }
    >
      <nav className="flex items-center py-3 max-w-screen-xl m-auto px-6">
        <div className="flex items-center space-x-1 flex-grow">
          <Link to="/" className="flex flex-row items-center space-x-2">
           
            <img src="../../images/GP copy.jpg" alt="logo" className="w-12 h-12 lg:w-16 lg:h-16 scale-110 object-contain" />
            <h1 className="text-2xl lg:text-4xl text-grad-600 font-extrabold">GradPeak</h1>
          </Link>
        
        </div>

        <div className="hidden md:flex lg:flex space-x-3">
          <ul className="flex items-center space-x-4">
            {menu.map((item) => (
              <li key={item.id}>
                <Link to={item.to} className="text-gray-600 text-sm" >
                  {item.text}
                </Link>
              </li>
            ))}
          </ul>

        
          {location?.pathname === "/" && (
            <div className="text-white flex flex-row space-x-3">
              <Link to="/disciplines">
                <button
                  className="grad_button px-4  py-2 text-sm text-white transition duration-300 rounded-lg"
                >
                  Enroll Now
                </button>
              </Link>
            </div>
          ) }
          
        </div>

        <div className="block md:hidden lg:hidden">
          <HiMenuAlt3
            className="w-8 h-8 text-gray-700  cursor-pointer rounded-lg  transform transition duration-200 hover:scale-110"
            onClick={handleClick}
          />
        </div>
      </nav>

      {mobileNav && (
        <div data-aos="zoom-in">
          <nav className="bg-white mx-6 mt-2 rounded-lg border border-gray-300 py-4 px-4 block md:hidden lg:hidden">
            <ul>
              {menu.map((item) => (
                <Link
                  to={item.to}
                  key={item.id}
                  className="text-gray-600 text-sm cursor-pointer"
                >
                  <li className="py-2 px-3 w-full hover:bg-gray-200 transition duration-300 cursor-pointer">
                    {item.text}
                  </li>
                </Link>
              ))}
            </ul>

            {location?.pathname === "/"  && (
              <div className="px-3 py-2">

                <Link to="/disciplines">
                  <button
                    className="grad_button px-4  py-2 text-sm text-white transition duration-300 rounded-lg"
                  >
                    Enroll Now
                  </button>
                </Link>

              </div>
            )}
            
          </nav>
        </div>
      )}

    </header>
  );
};

export default Navbar;
