import React from 'react';
import { FaFacebook, FaPhoneAlt } from "react-icons/fa";
import { FaLocationDot } from "react-icons/fa6";
import { MdEmail } from "react-icons/md";
import { Link } from "react-router-dom";

const Footer = () => {
     return (
          <>

               <footer className="bg-white py-8 mt-6">
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-12 max-w-screen-xl m-auto px-6">
                         <div className="cols-span-1 flex flex-col space-y-2">
                              <Link to="/" className="flex flex-row items-center space-x-2">

                                   <img src="../../images/GP copy.jpg" alt="logo" className="w-12 h-12 lg:w-16 lg:h-16 scale-110 object-contain" />
                                   <h1 className="text-2xl lg:text-4xl text-grad-600 font-extrabold">GradPeak</h1>
                              </Link>
                              <p className="text-sm text-gray-500 text-justify"> Explore your path to success with GradPeak: your ultimate resource for Graduate, PhD, and Master's programs. Discover top study abroad opportunities, admissions guidance, and expert advice to help you achieve your academic dreams. </p>

                         </div>
                        

                         <div className="cols-span-1 flex flex-col space-y-4 pt-1">
                              <div>
                                   <h2 className="text-xl font-bold text-safe-600"> Imporant Links </h2>
                                   <div className="w-12 h-1 mt-1 bg-safe-600 rounded-full"></div>
                              </div>
                              <ul className="text-sm text-gray-500 text-justify flex flex-col space-y-2 ">
                                   <li className="hover:underline cursor-pointer"> <Link to="/Disclaimer"> Disclaimer </Link> </li>
                                   <li className="hover:underline cursor-pointer"> <Link to="/Privacy Policy"> Privacy Policy</Link> </li>
                                   <li className="hover:underline cursor-pointer"> <Link to="/Refund Policy"> Refund Policy </Link> </li>
                                   <li className="hover:underline cursor-pointer"> <Link to="/Legal Disclaimer"> Legal Disclaimer </Link> </li>
                              </ul>
                         </div>



                         <div className="cols-span-1 flex flex-col space-y-4 pt-1">
                              <div>
                                   <h2 className="text-xl font-bold text-safe-600"> Contact </h2>
                                   <div className="w-12 h-1 mt-1 bg-safe-600 rounded-full"></div>
                              </div>
                              <ul className="text-sm text-gray-500 text-justify flex flex-col space-y-2 ">
                                   <li className="hover:underline cursor-pointer flex flex-row items-center space-x-2"> <FaLocationDot />  <span>Address: Mohakhali DOHS, Dhaka, Bangaladesh</span>  </li>
                                   <li className="hover:underline cursor-pointer flex flex-row items-center space-x-2 "> <MdEmail /> <span> Email: gradpeak.llc@gmail.com</span>  </li>
                                   <li className="hover:underline cursor-pointer flex flex-row items-center space-x-2"> <FaPhoneAlt /> <span>Phone: 01306990152 </span>   </li>
                                   <li className="hover:underline cursor-pointer flex flex-row items-center space-x-2">  <FaFacebook /> <a href="https://www.facebook.com/profile.php?id=61558121583496&mibextid=LQQJ4d" target="_blank">Facebook Page</a>  </li>
                              </ul>
                         </div>

                         <div className="cols-span-1 flex flex-col space-y-4 pt-1">
                              <div>
                                   <h2 className="text-xl font-bold text-safe-600"> Facebook Page </h2>
                                   <div className="w-12 h-1 mt-1 bg-safe-600 rounded-full"></div>
                              </div>
                              
                              <div>
                                   <iframe src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fprofile.php%3Fid%3D61558121583496%26mibextid%3DLQQJ4d&tabs=timeline&width=300&height=300&small_header=true&adapt_container_width=true&hide_cover=true&show_facepile=false&appId" width="300" height="70" className="border-none overflow-hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>                              </div>
                         </div>
                        
                    </div>

                    <div className="text-gray-500  flex-row items-center justify-center pt-6 max-w-screen-xl mx-auto px-6 ">
                         <p className="text-sm text-gray-500 text-center "> &copy; Copyright 2024 - Developed by <span className="text-gray-500 "> <a href="https://github.com/19smabtahinoor" target="_blank" className="underline cursor-pointer">Team GradPeak</a> </span></p>

                    </div>

                    {/* </div> */}
               </footer>
          </>
     )
}

export default Footer