import React from 'react'
import BrowserDiscipline from '../components/HomeScreen/BrowserDiscipline'
import ContactSec from '../components/HomeScreen/ContactSec'
import HeroSection from '../components/HomeScreen/HeroSection'
import MakeGraduateSchool from '../components/HomeScreen/MakeGraduateSchool'
import Mentors from '../components/HomeScreen/Mentors'
import StatsCard from '../components/HomeScreen/StatsCard'

const HomeScreen = () => {
    return (
        <>
            <HeroSection />
            <StatsCard />
            <Mentors />
            <BrowserDiscipline />
            <MakeGraduateSchool />
            <ContactSec />
        </>
    )
}

export default HomeScreen