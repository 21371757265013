import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ContactSec from '../components/HomeScreen/ContactSec';
import SectionTitle from '../components/SectionTitle';

import { RxCrossCircled } from "react-icons/rx";


const DisciplineScreenSingle = () => {
    const { link } = useParams();
    const [dis, setDis] = useState({});
    const [data, setData] = useState([])
    const [show,setShow] = useState(false)
    useEffect(() => {
        axios.get("../../db/disciplines.json").then((response) => {
            const arr = response.data;
            const item = arr.find((obj) => obj.link == link);
            setData(response?.data);

            setDis(item);
        });

        
    }, [data])

    return (
        <main>

            
            <section>
                <div className="max-w-screen-xl m-auto">
                    {/* parent div  */}
                    <div className="z-20 flex flex-col px-6 pt-32 lg:items-center lg:justify-center md:flex-col lg:flex-row lg:pt-28">
                        {/* main text and button */}
                        <div
                            data-aos="fade-right"
                            data-aos-duration="1200"
                            className="flex flex-col justify-center items-center space-y-3 lg:space-y-8 "
                        >
                            {/* <a href="#gradpeak"> */}
                            <h1 className="text-grad-600 text-center text-2xl lg:text-5xl  font-bold  tracking-tight leading-tight">
                                <span className="text-gray-700">Complete </span>
                                <span className="title_text">{dis?.name}</span> <br />
                                <span className="text-gray-700">Graduate School Preparation</span>
                            </h1>
                            {/* </a> */}

                            {/* buttons  */}
                            {/* <div className="grad_button flex flex-row space-x-2 lg:flex-col items-center p-4 lg:space-y-3 rounded-lg"> */}
                                {/* <h2 className="text-2xl lg:text-4xl font-bold text-white">{dis?.fees}</h2> */}

                                <button
                                    className=" grad_button px-5 py-3 lg:px-5 lg:py-3 text-sm lg:text-base border border-safe-600  rounded-lg hover:bg-safe-600 text-white font-semibold"

                                onClick={() => setShow(true)}
                                >
                                    Enroll Now
                                </button>



                            {/* </div> */}

                        </div>



                    </div>
                </div>
            </section>
           

           {/* modal  */}
            {show && (
                <>
                    <div className="modal absolute top-0 left-0 w-full flex flex-col justify-center items-center h-screen z-50 mx-auto">

                        <div className=" bg-white p-4 rounded-lg w-96 relative">
                            <div className="absolute top-5 right-5">
                                <RxCrossCircled className="cursor-pointer text-xl" onClick={() => setShow(false)} />
                            </div>
                            {/* headline  */}
                            <div className=" border-b border-gray-200">
                                <h1 className="text-gray-700 text-xl font-semibold text-center pb-2">Total Course Fees</h1>
                            </div>
                            <h1 className="text-4xl text-center title_text font-bold my-6">{dis?.fees}</h1>

                            <div className="flex flex-row space-x-3 items-center justify-center">
                                {/* <a href="" target="_blank"><button>Contact</button></a> */}
                                <a href="https://forms.gle/Xsvj9jchh9emLcG58" target="_blank"><button className="grad_button px-5 py-3 lg:px-5 lg:py-3 text-sm lg:text-base border border-safe-600  rounded-lg hover:bg-safe-600 text-white font-semibold">Confirm</button></a>
                            </div>
                        </div>
                    </div>
                </>
            )}

            {/* displines  */}
            <section>
                <div className="grid grid-cols-1 md:grid-cols-2 my-6 lg:grid-cols-3 gap-6 max-w-screen-xl m-auto p-6">
                    {dis?.subject?.map(item => {
                        return (
                            <>
                                <div key={item?.id} data-aos="fade-up"
                                    data-aos-duration={item?.duration}>
                                    <div className="select-none overflow-hidden flex flex-row p-4 space-x-3 bg-white rounded-lg" >
                                        <span>{item?.icon}</span>

                                        {/* view option  */}
                                        <div className="flex flex-col justify-center space-y-0">
                                            <h2 className="text-gray-600 text-sm font-semibold">{item?.name}</h2>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )
                    })}
                </div>
            </section>

            {/* features and supports  */}

            <section className="max-w-screen-xl m-auto p-6">

                <SectionTitle title="Features and Supports" />
                <div className="grid grid-cols-1 md:grid-cols-2 my-6 lg:grid-cols-3 gap-6 ">

                    {/* box1  */}
                    <div className="bg-white rounded-lg p-6 flex flex-col space-y-2" data-aos="fade-up"
                        data-aos-duration="1200">

                        {/* headline  */}
                        <div>
                            <h3 className="font-semibold text-gray-700 text-lg">Application Strategy and Planning</h3>
                        </div>

                        {/* details  */}
                        <div className="px-8 text-gray-500 text-sm">
                            <ul style={{ listStyleType: 'disc' }}>
                                <li>Initial consultation</li>
                                <li>Program selection</li>
                                <li>Timeline creation</li>
                            </ul>

                        </div>

                    </div>

                    {/* box 2 */}
                    <div className="bg-white rounded-lg p-6 flex flex-col space-y-2" data-aos="fade-up"
                        data-aos-duration="1200"  data-aos="fade-up"
                        data-aos-duration="1200">

                        {/* headline  */}
                        <div>
                            <h3 className="font-semibold text-gray-700 text-lg">Document Preparation</h3>
                        </div>

                        {/* details  */}
                        <div className="px-8 text-gray-500 text-sm">
                            <ul style={{ listStyleType: 'disc' }}>
                                <li> Statement of Purpose (SOP) assistance</li>
                                <li>Personal statement help</li>
                                <li>Resume/CV crafting</li>
                                <li>Writing sample feedback</li>
                            </ul>

                        </div>

                    </div>


                    {/* box 3*/}
                    <div className="bg-white rounded-lg p-6 flex flex-col space-y-2"  data-aos="fade-up"
                        data-aos-duration="1200">

                        {/* headline  */}
                        <div>
                            <h3 className="font-semibold text-gray-700 text-lg">Recommendation Letters</h3>
                        </div>

                        {/* details  */}
                        <div className="px-8 text-gray-500 text-sm">
                            <ul style={{ listStyleType: 'disc' }}>
                                <li>Recommender selection advice</li>
                                <li>Preparation of information packets for recommenders</li>
                            </ul>

                        </div>

                    </div>

                    {/* box 4*/}
                    <div className="bg-white rounded-lg p-6 flex flex-col space-y-2"  data-aos="fade-up"
                        data-aos-duration="1200">

                        {/* headline  */}
                        <div>
                            <h3 className="font-semibold text-gray-700 text-lg">Interview Preparation</h3>
                        </div>

                        {/* details  */}
                        <div className="px-8 text-gray-500 text-sm">
                            <ul style={{ listStyleType: 'disc' }}>
                                <li>Mock interviews</li>
                                <li>Performance feedback and improvement tips</li>
                            </ul>

                        </div>

                    </div>

                    {/* box 5*/}
                    <div className="bg-white rounded-lg p-6 flex flex-col space-y-2"  data-aos="fade-up"
                        data-aos-duration="1200">

                        {/* headline  */}
                        <div>
                            <h3 className="font-semibold text-gray-700 text-lg">Portfolio Development (for relevant fields)</h3>
                        </div>

                        {/* details  */}
                        <div className="px-8 text-gray-500 text-sm">
                            <ul style={{ listStyleType: 'disc' }}>
                                <li> Guidance on portfolio content and format</li>
                                <li> Feedback on portfolio pieces</li>
                            </ul>

                        </div>

                    </div>


                    {/* box 6*/}
                    <div className="bg-white rounded-lg p-6 flex flex-col space-y-2"  data-aos="fade-up"
                        data-aos-duration="1200">

                        {/* headline  */}
                        <div>
                            <h3 className="font-semibold text-gray-700 text-lg">Application Form Assistance</h3>
                        </div>

                        {/* details  */}
                        <div className="px-8 text-gray-500 text-sm">
                            <ul style={{ listStyleType: 'disc' }}>
                                <li> Help with completing application forms accurately</li>
                                <li> Review of application before submission</li>
                            </ul>

                        </div>

                    </div>

                    {/* box 7*/}
                    <div className="bg-white rounded-lg p-6 flex flex-col space-y-2"  data-aos="fade-up"
                        data-aos-duration="1200">

                        {/* headline  */}
                        <div>
                            <h3 className="font-semibold text-gray-700 text-lg">Financial Aid and Scholarships</h3>
                        </div>

                        {/* details  */}
                        <div className="px-8 text-gray-500 text-sm">
                            <ul style={{ listStyleType: 'disc' }}>
                                <li>Information on financial aid options</li>
                                <li>Guidance on scholarship applications</li>
                            </ul>

                        </div>

                    </div>

                    {/* box 8*/}
                    <div className="bg-white rounded-lg p-6 flex flex-col space-y-2"  data-aos="fade-up"
                        data-aos-duration="1200">

                        {/* headline  */}
                        <div>
                            <h3 className="font-semibold text-gray-700 text-lg">Follow-Up and Status Tracking</h3>
                        </div>

                        {/* details  */}
                        <div className="px-8 text-gray-500 text-sm">
                            <ul style={{ listStyleType: 'disc' }}>
                                <li>Assistance with tracking application status</li>
                                <li>Guidance on follow-up communications with programs</li>
                            </ul>

                        </div>

                    </div>



                </div>
            </section>

            <ContactSec />
            
        </main>
    )
}

export default DisciplineScreenSingle