import React from "react";
import { useNavigate } from "react-router-dom";
import { Typewriter } from 'react-simple-typewriter';

const HeroSection = () => {
  const navigate = useNavigate();

  const handleNavigation = () => {
    navigate("/features");
  };

   
  return (
    <main>
      <section>
        <div className="max-w-screen-xl m-auto">
          {/* parent div  */}
          <div className="z-20 flex flex-col px-6 pt-32 lg:items-center lg:justify-between md:flex-col lg:flex-row lg:pt-28">
            {/* main text and button */}
            <div
              data-aos="fade-right"
              data-aos-duration="1200"
              className="flex flex-col items-start space-y-4 lg:space-y-8 "
            >
              <h1 className="text-grad-600 text-3xl lg:text-5xl  font-bold  tracking-tight leading-tight">
                <span className="text-gray-700"> Get accepted to <span className="title_text">
                  <Typewriter words={['PhD', 'Masters']} loop={9999999} cursor
                     delaySpeed={1000} typeSpeed={70}
                    deleteSpeed={50}/></span> </span>
                
                <br /><span className="text-gray-700">Seamlessly</span>
              </h1>
              <p className="text-base text-gray-600 ">Your graduate school admission concerns are our worries.</p>
             
             {/* buttons  */}
             <div className="flex flex-row items-center space-x-3">
                <button
                  className="grad_button px-5 py-3 lg:px-5 lg:py-3 text-sm lg:text-base border border-safe-600  rounded-lg hover:bg-safe-600 text-white font-semibold"
                  onClick={handleNavigation}
                >
                  Browse Features
                </button>
                {/* <button
                  className="text-grad-500 px-5 py-3 text-base border border-safe-600  rounded-lg border-grad-500 hover:grad_button font-semibold hover:bg-gray-100 transition duration-300"
                  onClick={handleNavigation}
                >
                  Enroll Now
                </button> */}
             </div>

            </div>

            {/* vector gfx  */}
            <div>

              <div data-aos="fade-left" data-aos-duration="1200">
                <img
                  className="relative hero_section_vector object-contain"
                  src="../../../images/hero.svg"
                  alt="hero section vector"
                />
              </div>
            </div>

          </div>
        </div>
     </section>


  
    </main>
  );
};

export default HeroSection;
