import React from 'react'

const StatsCard = () => {
    const data = [
        { id: 1, number: "60+", title:"Previously Mentored "},
        { id: 2, number: "9.3M+", title:"Financial Aid"},
        { id: 3, number: "7+", title:"Expert Mentors "},
        { id: 4, number: "3+", title:"years of experience"},
    ]
  return (
      <div className=" max-w-screen-xl mx-auto px-6 ">

          {/* <div className="flex flex-col lg:flex-row space-y-3 lg:space-y-0 justify-around items-start lg:items-center statsCardbg rounded-lg p-8"> */}
              <div className="grid grid-cols-1 lg:grid-cols-4 place-items-center gap-12 statsCardbg rounded-lg p-8">
              {data?.map(item => {
                  return (
                      <>
                          <div key={item?.id} className="col-span-1 flex flex-col items-center justify-center text-white" data-aos="fade-up"
                              data-aos-duration="1200">
                              <h1 className="text-5xl font-bold">{item?.number}</h1>
                              <h3 className="text-base lg:text-xl font-semibold">{item?.title}</h3>
                          </div>
                      </>
                  )
              })}
         </div>
    </div>
  )
}

export default StatsCard