import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SectionTitle from '../SectionTitle';
// Import Swiper styles
import { FaLinkedin } from "react-icons/fa";
import { FaGoogleScholar } from "react-icons/fa6";
import 'swiper/css';
import 'swiper/css/navigation';


// import required modules
import { Navigation } from 'swiper/modules';

import '../../index.css';

// import required modules
// import { EffectCoverflow, Pagination } from 'swiper/modules';

const Mentors = () => {

  const team = [
    {
      id: 1, name: "Asif Shahriar Sushmit", image: "../../team/team_2.jpg", study: "Co-Ordinator, Bengali.Ai", university_logo: "../../../images/1.jpeg", duration: "1100", google_scholar: "https://scholar.google.com/citations?user=S3FHKh4AAAAJ&hl=en", linkedin:"https://www.linkedin.com/in/sushmit109/?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app" },
    { id: 2, name: "Md Tariful Islam", image: "../../team/team_1.jpg", study: "PhD Student,RPI", university_logo: "../../../images/2.png", duration: "1100", google_scholar: "https://enzymes.che.rpi.edu/people/md-tariful-islam-0", linkedin: "https://www.linkedin.com/in/md-tariful-islam007?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app" },
  

   
  ]

  return (
    <section className="max-w-screen-xl mx-auto px-6 my-24">
    {/* Mentors /> */}

    <SectionTitle title="Meet Your Mentors" />
      {/* <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 max-w-screen-xl m-auto p-6 place-items-center"> */}

        <Swiper
        grabCursor={true}
        navigation={true} modules={[Navigation]}
        className="mySwiper mt-8 py-8"
        spaceBetween={15}
        slidesPerView={1}
        loop={false}
        autoplay={{
          delay: 2000,
          disableOnInteraction: false
        }}
        breakpoints={{
        //  / when window width is >= 640px
      
        375: {
          slidesPerView: 1,
        },
        412: {
          slidesPerView: 1,
        },
        640: {
          slidesPerView: 1,
        },
        // when window width is >= 768px
        768: {
          slidesPerView: 1,
        },
        // when window width is >= 1024px
        1024: {
          slidesPerView: 3,
        },
        }}
        data-aos="fade-up"
        data-aos-duration="1200"
        >
          
       

        {team?.map(item => {
          return (
            <>
              <SwiperSlide key={item?.id} className="select-none overflow-hidden flex flex-col bg-white rounded-lg " >
                <div className="w-full h-72 rounded-tr-lg rounded-tl-lg overflow-hidden">
                  <img className="w-full h-full object-cover" src={item?.image} alt={item?.link} />
                </div>

                {/* view option  */}
                <div className="flex flex-row items-center justify-between p-4 pb-4 bg-white rounded-lg">
                  <div className="flex flex-col space-y-0">
                    <h2 className="text-safe-600 text-lg font-semibold">{item?.name}</h2>
                    <span className=" text-gray-600 text-xs">{item?.study}</span>

                    <div className="flex flex-row items-center space-x-2 text-gray-600 text-lg pt-2 ">
                      <a href={item?.google_scholar} target="_blank"><FaGoogleScholar /> </a>
                      <a href={item?.linkedin} target="_blank"><FaLinkedin /></a>
                      
                      
                    </div>
                  </div>

                  {/* uni logo  */}
                  <div>
                    <img className="w-28 h-8 object-contain" src={item?.university_logo} alt="uni logo" />
                  </div>


                
                </div>

              </SwiperSlide>
            </>
          )
        })}
        <SwiperSlide className="select-none overflow-hidden flex flex-col h-full bg-white rounded-lg " >
          <div className="flex flex-col items-center justify-center h-96 bg-white">
            <h1>Coming soon...</h1>
          </div>

        </SwiperSlide>
        </Swiper>
    {/* </div> */}
    </section>
  )
}



export default Mentors